/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Fullmap, FullmapWrap, FullmapCover, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-zkm32h css-enc1f8 --full --parallax pb--16" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e16368d521de45cdad7ccb88fe33306d_ove=ff5000x8__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--shadow4 mb--16 mt--16 pl--0 pr--0 pt--0" style={{"maxWidth":500,"paddingBottom":0,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/0ba0804db8114ea18a5719da3fd73758_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={"https://cdn.swbpg.com/t/25749/0ba0804db8114ea18a5719da3fd73758_s=350x_.png 350w, https://cdn.swbpg.com/t/25749/0ba0804db8114ea18a5719da3fd73758_s=660x_.png 660w, https://cdn.swbpg.com/t/25749/0ba0804db8114ea18a5719da3fd73758_s=860x_.png 860w, https://cdn.swbpg.com/t/25749/0ba0804db8114ea18a5719da3fd73758_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"o-nas"} layout={"l4"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":350}} srcSet={"https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25749/7844966330fa492ca4f17a9848d5cb0c_s=2000x_.jpg 2000w"}>
              </Image>

              <Title className="title-box swpf--uppercase" style={{"paddingRight":6}} content={"<span style=\"color: var(--color-custom-1);\">Zuzka &amp; aleš</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"<span style=\"color: rgb(98, 72, 42);\">Celý život bydleli 5 km od sebe, a přesto se neznali. Dohromady je dala až&nbsp;</span><span style=\"color: rgb(98, 72, 42);\">sociální síť v roce 2016.<br></span><img class=\"image css-0\" src=\"https://cdn.swbpg.com/o/25749/5c2b0364a93947228a6b76b465832b5c.jpg\" sizes=\"100vw\" alt=\"\" style=\"font-size: 18px; font-weight: 300; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-family: Oswald, serif; letter-spacing: 0.18px;\"><span style=\"color: rgb(98, 72, 42);\"><br>Brzy na to proběhla první zahraniční dovolená, a to rovnou do Vietnamu.&nbsp;</span><span style=\"color: rgb(98, 72, 42);\">Po této důležité zkoušce vztahu začali společně bydlet. Jejich lásku definitivně zpečetilo narození dcery Moniky. <br></span><img class=\"image css-0\" src=\"https://cdn.swbpg.com/o/25749/5c2b0364a93947228a6b76b465832b5c.jpg\" sizes=\"100vw\" alt=\"\"><span style=\"color: rgb(98, 72, 42);\"><br>Jako rodina už fungují... už chybí jen stejné příjmení.</span><br>"}>
              </Text>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"l6drjg5zind"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Zámek Lešany</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-supplementary);\">Na zámek Lešany přivedla novomanžele v podstatě náhoda, ale ihned si ho oblíbili a rozhodli se uskutečnit jejich důležitý den právě zde.</span><br><br><span style=\"color: var(--color-supplementary);\">Kromě citlivě zrekonstruovaného zámku na svatební hosty čeká i venkovní altán v zahradě s třistaletými duby, kde proběhne obřad, venkovní pergola pro svatební hostinu, herna s bowlingovými dráhami a další zajímavosti areálu.</span><br><br><span style=\"color: var(--color-supplementary);\">Parkování je zajištěno přímo v areálu zámku. Nachází se zde i široké možnosti ubytování, které si svatební hosté mohou domluvit přímo s novomanželi nebo v níže uvedeném formuláři. </span><br><br><span style=\"color: var(--color-supplementary);\">Více o zámku Lešany zde: </span><a href=\"https://www.zameklesany.cz/\" target=\"_blank\" style=\"\">zameklesany.cz</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/53598811d5684471a9d725614f0f3149_e=624x0x1441x1440_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25749/53598811d5684471a9d725614f0f3149_e=624x0x1441x1440_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25749/53598811d5684471a9d725614f0f3149_e=624x0x1441x1440_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25749/53598811d5684471a9d725614f0f3149_e=624x0x1441x1440_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/25749/53598811d5684471a9d725614f0f3149_e=624x0x1441x1440_s=1400x_.jpeg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"hzmopj9vkst"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"9"} place={"Zámek Lešany"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="pb--60 pt--60" name={"9m1uj96a0if"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box subtitle-box--left pl--0" style={{"maxWidth":562}} content={"<span style=\"color: var(--color-supplementary);\">Mapa areálu</span>"}>
              </Subtitle>

              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=860x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=350x_.png 350w, https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=660x_.png 660w, https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=860x_.png 860w, https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=1400x_.png 1400w, https://cdn.swbpg.com/t/25749/a19e00ba5c78451a9a85d35c562821a1_s=2000x_.png 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-g8vkdo --parallax pb--80 pt--80" name={"program"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25749/e8d7707812374ee586b2b4d4c93093b0__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Program</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: rgb(98, 72, 42);\">Svatební den je především o uvolněné atmosféře, a proto není kladen důraz na přesnost harmonogramu. Obřad začne sice na čas, ale následující body jsou spíše orientační.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">12:00</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: var(--color-supplementary);\">VÍTÁNÍ SVATEBČANŮ</span>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">13:00</span>"}>
              </Title>

              <Text className="text-box fs--26 mt--10" content={"<span style=\"color: var(--color-supplementary);\">OBŘAD</span>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">14:30</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: var(--color-supplementary);\">SVATEBNÍ HOSTINA</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">16:00</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: var(--color-supplementary);\">PROHLÍDKA ZÁMKU</span>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">17:00</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: var(--color-supplementary);\">KRÁJENÍ DORTU</span>"}>
              </Text>

              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">18:00+</span>"}>
              </Title>

              <Text className="text-box mt--10" content={"<span style=\"color: var(--color-supplementary);\">VEČERNÍ RAUT, PRVNÍ TANEC, VOLNÁ ZÁBAVA</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"6klzjizma6t"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

              <Title className="title-box title-box--center" content={"<span style=\"color: var(--color-custom-1);\">Důležité osoby</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25749/7df61b5fe9f54f2fb6af792bc27fccfc_s=2000x_.jpg 2000w"}>
              </Image>

              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-1);\">Bára Kouklová</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-supplementary);\">Svědkyně nevěsty</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=860x_.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=350x_.jpg 350w, https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=660x_.jpg 660w, https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=860x_.jpg 860w, https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/25749/5bc0ac719216423199235217d3f2fa87_s=2000x_.jpg 2000w"}>
              </Image>

              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-1);\">Michal Křivánek</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-supplementary);\">Svědek ženicha</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/25749/57280c0395854f88af6ba6d1de229053.jpg"} sizes={"100vw"} style={{"maxWidth":260}} srcSet={""}>
              </Image>

              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-1);\">Martin Truhlář</span>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-supplementary);\">Zámecký pán</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"info"} style={{"paddingBottom":null,"backgroundColor":"rgba(255,255,255,1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--20 pt--20" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

              <Title className="title-box title-box--center" style={{"maxWidth":522}} content={"<span style=\"color: var(--color-custom-1);\">Další informace</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--3 pb--40 pt--30 flex--stretch" columns={"3"}>
            
            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--25" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/25749/089257746ae64d7b99b954d44928e158.svg"} style={{"maxWidth":128}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">DRESS CODE</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":219}} content={"<span style=\"color: var(--color-supplementary);\">Dress code není striktně stanoven.\n\nPánové mohou přijít bez ostychu v košili a společenských kalhotách/kraťasech. Dámy mohou přijít v čem uznají za vhodné. Terén však není ideální pro vysoké a jehlové podpatky.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--30" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/25749/f7a62b9ed1a642e9bb47f0422d745225.svg"} style={{"maxWidth":128}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":264,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"color: var(--color-custom-1);\">SVATEBNÍ DARY</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":222}} content={"<span style=\"color: var(--color-supplementary);\">Vaše účast na svatbě je tím hlavním darem. Zda i přesto chcete něčím potěšit, příspěvek na svatební cestu novomanželé ocení nejvíce.<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--30" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/25749/b41bd04321cd4b5697eaeee8bbb7f3a0.svg"} style={{"maxWidth":128}} srcSet={""} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-custom-1);\">FOCENÍ</span>"}>
              </Subtitle>

              <Text className="text-box text-box--left" style={{"maxWidth":222}} content={"<span style=\"color: var(--color-supplementary);\">Během obřadu prosím nefoťte ani nenatáčejte. Veškeré fotografie naleznete na tomto webu pár dní po svatbě.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"formular"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/25749/64c1da0fc4de41df96393c16ed37b859.svg"} sizes={"100vw"} style={{"maxWidth":1000}} srcSet={""}>
              </Image>

              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Možnost ubytování</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":536}} content={"<span style=\"color: var(--color-supplementary);\">Areál nabízí dostatečné kapacity ubytování pro všechny hosty. Pokud jste si nedomluvili ubytování přímo s novomanžely, zde máte možnost poptat konkrétní přenocování.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 fs--20 w--300" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Jméno","type":"text","required":true,"placeholder":"Jméno"},{"name":"Počet dospělých","type":"text","required":true,"placeholder":"Počet dospělých"},{"name":"Počet dětí","type":"text","required":false,"placeholder":"Počet dětí"},{"name":"Jak to bude?","type":"select","options":["Rád bych standard pokoj (650,-/os)","Potrpím si na komfort (750,-/os)","Chci na zámek (dle velikosti pokoje)","Mám malý stan / velké auto (individuální)","Nechci spát (free)"],"required":true,"placeholder":"Jak to bude?"},{"name":"Je libo snídaně?","type":"select","options":["Snídaně standard raut (130,-/os)","Snídaně standard + míchaná vajíčka a párečky (150,-os)"],"required":false,"placeholder":"Je libo snídaně?"},{"name":"Poznámka či dotaz:","type":"text","placeholder":"Poznámka či dotaz:"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}